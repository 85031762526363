<template>
    <v-row>
        <v-dialog v-model="dialogErro.status" persistent>
            <CardDialog :dialog="dialogErro" />
        </v-dialog>
        <v-dialog persistent fullscreen scrollable v-model="dialogCliente">
            <v-card flat elevation="0">
                <ClienteNovo :dialog-cliente="true" @fechar="dialogCliente = false"></ClienteNovo>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogConfirmacaoPedido" max-width="500">
            <v-card>
                <v-card-title class="text-h5">Confirmar geração do pedido de venda?</v-card-title>
                <v-divider />
                <div class="text-center py-3">
                    <v-icon size="100" color="red">mdi-help-circle-outline</v-icon>
                </div>
                <v-divider />
                <v-card-actions class="pa-3">
                    <v-spacer />
                    <v-btn
                        :loading="carregando"
                        text
                        color="primary"
                        elevation="0"
                        @click="dialogConfirmacaoPedido = false"
                    >Cancelar</v-btn>
                    <v-btn :loading="carregando" color="primary" elevation="0" @click="gerarPedido()">Gerar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogConfirmacaoOrcamento" max-width="500">
            <v-card>
                <v-card-title class="text-h5">Confirmar geração do orçamento?</v-card-title>
                <v-divider />
                <div class="text-center py-3">
                    <v-icon size="100" color="red">mdi-help-circle-outline</v-icon>
                </div>
                <v-divider />
                <v-card-actions class="pa-3">
                    <v-spacer />
                    <v-btn
                        :loading="carregando"
                        text
                        color="primary"
                        elevation="0"
                        @click="dialogConfirmacaoOrcamento = false"
                    >Cancelar</v-btn>
                    <v-btn :loading="carregando" color="primary" elevation="0" @click="gerarOrcamento()">Gerar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <VendaProdutoDialog
            v-if="dialogProduto"
            :random="Math.random()"
            :dialog="dialogProduto"
            :produto="produto"
            @onClose="dialogProduto = false"
            @onSearch="dialogProduto = false, $router.push({ path: '/venda', name: 'VendaProduto',  params: { buscaProduto: $event} })"
        />
        <v-col cols="12" md="7" lg="8" xl="9" order="2" order-md="1">
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col cols="12" md="3" class="pr-1">Lista de produtos</v-col>
                        <v-col cols="12" md="3" class="px-1">
                            <v-autocomplete
                                class="mb-n10"
                                label="Vendedor"
                                v-model="vendedorGeral"
                                outlined
                                dense
                                :items="vendedores"
                                item-text="nome"
                                item-value="idcliente"
                                @change="atualizarVendedorGeral()"
                                :disabled="carregando"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3" class="px-1">
                            <v-select
                                class="mb-n10"
                                label="Ponto de retirada"
                                :items="locaisretirada"
                                item-value="idlocalretirada"
                                item-text="localretirada"
                                outlined
                                dense
                                append-icon="mdi-chevron-down"
                                v-model="pontoRetiradaGeral"
                                @change="atualizarPontoRetiradaGeral()"
                                :disabled="carregando"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="3" class="px-1 pr-md-2">
                            <v-text-field
                                v-mask="'##.##'"
                                v-model="descontoGeral"
                                dense
                                outlined
                                label="Desconto"
                                append-icon="%"
                                @change="atualizarDescontoGeral()"
                                :disabled="carregando || step > 1"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th style="width: 1%" class="pl-2 pr-0 text-center" v-if="produtos.filter(u => u.promocao !=0).length > 0">
                                    <v-tooltip :disabled="carregando || step > 1" open-delay="200" right :color="flagpromocaotodos ? 'error' : 'primary' " content-class='custom-tooltip'>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <v-switch
                                                    :disabled="carregando || step > 1"
                                                    v-model="flagpromocaotodos"
                                                    @click="alteraStatusPromocaoGeral()"
                                                    inset
                                                    hide-details
                                                    dense
                                                    color="primary"
                                                    class="mt-n1 mr-n1 pl-1"
                                                />
                                            </div>
                                        </template>
                                        <span v-if="flagpromocaotodos">Desativar todas promoções</span>
                                        <span v-else>Ativar todas promoções</span>
                                    </v-tooltip>
                                </th>
                                <th style="width: 4%" class="pr-2 pl-0">Código</th>
                                <th style="width: 13%" class="px-2">Produto</th>
                                <th style="width: 16%" class="px-2">Quantidade</th>
                                <th style="width: 19%" class="px-2">Vendedor</th>
                                <th style="width: 21%" class="px-2">Ponto retirada</th>
                                <th style="width: 7%" class="px-2">Valor</th>
                                <th style="width: 7%" class="px-2">Desconto</th>
                                <th style="width: 7%" class="px-2">Subtotal</th>
                                <th style="width: 1%" class="px-2"></th>
                            </tr>
                        </thead>
                        <tbody>
                        <template v-if="produtos.length > 0">
                            <tr v-for="(item, i) in produtos" :key="i">
                                <td style="width: 1%; font-size: 0.9em" class="pl-2 pr-0 text-truncate text-center" v-if="produtos.filter(u => u.promocao !=0).length > 0">
                                    <v-tooltip :disabled="carregando || step > 1" open-delay="200" right :color="item.flagpromocao ? 'error' : 'primary' " content-class='custom-tooltip'>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <v-switch
                                                    v-model="item.flagpromocao"
                                                    @click="atualizarProdutoPedido(item,i),atualizaArrayProdutos()"
                                                    inset
                                                    hide-details
                                                    dense
                                                    v-if="item.dtfimpromocao != ''"
                                                    color="primary"
                                                    class="mt-n1 mr-n1 pl-1"
                                                    :disabled="carregando || step > 1"
                                                />
                                            </div>
                                        </template>
                                        <span v-if="item.flagpromocao">Desativar promoção</span>
                                        <span v-else>Ativar promoção</span>
                                    </v-tooltip>
                                </td>
                                <td style="width: 4%; font-size: 0.9em" class="pr-2 pl-0 text-truncate">{{item.idsubproduto}}</td>
                                <td style="width: 13%; font-size: 0.9em" class="px-2 text-truncate">
                                    <v-tooltip open-delay="200" right color="white" content-class='custom-tooltip'>
                                        <template v-slot:activator="{ on }">
                                            <span class="textoClicavel" v-on="on" @click="get(item),dialogProduto = true">{{item.descricao | formataTextoPedido}}</span>
                                        </template>
                                        <v-card elevation="0"  style="min-width: 350px">
                                            <v-card-title style="font-size: 1.1em" class="pt-1">
                                                {{item.descricao}}
                                            </v-card-title>
                                            <v-card-text align="center">
                                                <v-img width="250px" height="200px"
                                                    v-if="item.linkimagem" :src="item.linkimagem" />
                                                <v-img width="200px" height="200px"
                                                    v-else src="../../assets/icons/sem_imagem.png" />
                                            </v-card-text>
                                            <v-card dark color="primary" flat>
                                                <v-card-title class="text-h6 py-2">Detalhes</v-card-title>
                                                <v-divider />
                                                <v-card-text class="white--text py-2">
                                                    <div class="float-left">
                                                        <div class="py-1">
                                                            <v-icon class="mr-1" size="21">mdi-dolly</v-icon>Estoque
                                                        </div>
                                                        <div class="py-1">
                                                            <v-icon class="mr-1" size="21">mdi-package-variant-closed</v-icon>Reserva
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <div class="py-1">{{item.estoque.toFixed(2)}} {{item.embalagem}}(s)</div>
                                                        <div class="py-1">{{item.reserva.toFixed(2)}} {{item.embalagem}}(s)</div>
                                                    </div>
                                                </v-card-text>
                                                <v-divider />
                                                <v-card-text class="white--text py-2" v-if="item.dtfimpromocao != ''">
                                                    <div class="float-left">
                                                        <div class="py-1">
                                                            <v-icon class="mr-1" size="21">mdi-cash-multiple</v-icon>Preço normal
                                                        </div>
                                                        <div class="py-1">
                                                            <v-icon class="mr-1" size="21">mdi-sale</v-icon>Preço promocional
                                                        </div>
                                                        <div class="py-1">
                                                            <v-icon class="mr-1" size="21">mdi-calendar-clock</v-icon>Fim da promoção
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <div class="py-1">{{item.venda | formataDinheiro}}</div>
                                                        <div class="py-1">{{item.promocao | formataDinheiro}}</div>
                                                        <div class="py-1">{{ item.dtfimpromocao }}</div>
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-card>
                                    </v-tooltip>
                                </td>
                                <td style="width: 16%" class="px-2">
                                    <v-text-field
                                        style="min-width: 150px; font-size: 0.9em"
                                        v-if="item.embalagem == 'M2' || item.valmultivendas != 1"
                                        class="mb-n3"
                                        :suffix="(`${(item.quantidade*item.valmultivendas).toFixed(3)} ${item.embalagem}`).replaceAll('.',',')"
                                        persistent-hint
                                        dense
                                        v-model.number="item.quantidade"
                                        v-mask="'########'"
                                        @change="atualizarProdutoPedido(item, i)"
                                        :disabled="carregando || step > 1"
                                    />
                                    <v-text-field
                                        style="min-width: 100px; font-size: 0.9em"
                                        v-else-if="item.embalagem == 'MT'"
                                        class="mb-n3"
                                        :suffix="item.embalagem"
                                        persistent-hint
                                        dense
                                        v-model.number="item.quantidade"
                                        v-mask="'########'"
                                        @change="atualizarProdutoPedido(item, i)"
                                        :disabled="carregando || step > 1"
                                    />
                                    <v-text-field
                                        style="min-width: 110px; font-size: 0.9em"
                                        v-else
                                        class="mb-n3"
                                        :suffix="item.embalagem"
                                        persistent-hint
                                        dense
                                        v-model.number="item.quantidade"
                                        v-mask="'########'"
                                        @change="atualizarProdutoPedido(item, i)"
                                        :disabled="carregando || step > 1"
                                    />
                                </td>
                                <td style="width: 19%" class="px-2">
                                    <v-autocomplete
                                        style="min-width: 80px; font-size: 0.9em"
                                        class="mb-n3"
                                        v-model="item.idvendedor"
                                        dense
                                        :items="vendedores"
                                        item-text="nome"
                                        item-value="idcliente"
                                        @change="atualizarProdutoPedido(item, i)"
                                        :disabled="carregando"
                                    ></v-autocomplete>
                                </td>
                                <td style="width: 21%" class="px-2">
                                    <v-autocomplete
                                        style="min-width: 100px; font-size: 0.9em"
                                        class="mb-n3"
                                        :items="locaisretirada"
                                        item-value="idlocalretirada"
                                        item-text="localretirada"
                                        v-model="item.idlocalretirada"
                                        dense
                                        @change="atualizarProdutoPedido(item, i)"
                                        :disabled="carregando"
                                    ></v-autocomplete>
                                </td>
                                <td style="width: 7%; position: relative" class="text-truncate px-2 pr-5">
                                    <v-row no-gutters>
                                        <v-col v-if="item.flagpromocao && item.promocao != 0" cols="12">
                                            <span>{{item.promocao | formataDinheiro}}</span>
                                            <v-tooltip top color="primary" dark>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn style="position: absolute;right: 0px;top: 9px; height: 15px; width: 15px" text fab v-on="on">
                                                        <v-icon color="primary" size="15">mdi-alpha-i-circle-outline</v-icon>
                                                    </v-btn>

                                                </template>
                                                <v-icon size="20" class="mr-2" color="white">mdi-alpha-i-circle-outline</v-icon>
                                                <span class="primary">Preço promocional.</span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col v-else cols="12">{{item.venda | formataDinheiro}}</v-col>
                                    </v-row>
                                </td>
                                <td style="width: 7%" class="text-truncate px-2">
                                    <v-text-field
                                        v-mask="'##.##'"
                                        style="min-width: 50px; max-width: 60px; font-size: 0.9em"
                                        prefix="%"
                                        v-model="item.desconto"
                                        reverse
                                        dense
                                        class="mb-n3"
                                        @change="atualizarProdutoPedido(item, i)"
                                        :disabled="carregando || step > 1 || item.flagpromocao && item.promocao != 0"
                                    ></v-text-field>
                                </td>
                                <td style="width: 7%" class="text-truncate px-2">{{calculaSubTotalProdutoTabela(item) | formataDinheiro}}</td>
                                <td style="width: 1%" class="px-2">
                                    <v-btn
                                        :disabled="carregando || step > 1"
                                        x-small
                                        class="error"
                                        elevation="0"
                                        fab
                                        @click="excluirProdutoPedido(i)"
                                        height="25px"
                                        width="25px"
                                    >
                                        <v-icon>mdi-minus</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td class="text-center" colspan="8">Nenhum produto adicionado ao pedido</td>
                            </tr>
                        </template>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="5" lg="4" xl="3" order="1" order-md="2">
            <v-card>
                <v-card-title>* Resumo do pedido</v-card-title>
                <v-card-text>
<!--                    {{pedido.pagamento}}-->
                    <v-stepper v-model="step" vertical flat>
                        <v-stepper-step
                            :complete="step > 1"
                            step="1"
                            class="px-0 py-1 font-weight-bold"
                            editable
                        >Resumo dos produtos</v-stepper-step>
                        <v-stepper-content step="1" class="px-2 mx-0 font-weight-bold">
                            <v-row align="end">
                                <v-col>
                                    <div class="py-2 text-body-1 font-weight-bold">
                                        <v-icon size="18" class="mr-1 mt-n1">mdi-cart-arrow-down</v-icon>
                                        {{produtos.length}}
                                        Produto(s)
                                    </div>
                                    <div class="py-2 text-body-1 font-weight-bold">
                                        <v-icon size="18" class="mr-1 mt-n1">mdi-sale</v-icon>Desconto
                                    </div>
                                    <div class="py-2 text-body-1 font-weight-bold">
                                        <v-icon size="18" class="mr-1 mt-n1">mdi-cart-check</v-icon>Total
                                    </div>
                                </v-col>
                                <v-col cols="auto" class="text-right">
                                    <div class="py-2 text-body-1 font-weight-bold">{{calculaValor() | formataDinheiro}}</div>
                                    <div
                                        class="py-2 text-body-1 font-weight-bold"
                                    >{{(calculaValor()-calculaValorTotal()) | formataDinheiro}}</div>
                                    <div class="py-2 text-body-1 font-weight-bold">{{calculaValorTotal() | formataDinheiro}}</div>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn
                                        :disabled="verificaStep1()"
                                        color="primary"
                                        small
                                        @click="step = 2, limparPedido()"
                                    >Próximo</v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                        <v-stepper-step
                            :complete="step > 2"
                            step="2"
                            class="px-0 py-1 font-weight-bold"
                        >Dados do cliente e vendedor</v-stepper-step>
                        <v-stepper-content step="2" class="px-2 mx-0 font-weight-bold">
                            <v-row align="end">
                                <v-col cols="12">
                                    <div class="text-body-1 font-weight-bold">
                                        <v-row no-gutters>
                                            <v-col align-self="end">Cliente</v-col>
                                            <v-col cols="auto">
                                                <v-btn
                                                    fab
                                                    x-small
                                                    elevation="0"
                                                    color="primary"
                                                    class="mb-1"
                                                    @click="dialogCliente = true"
                                                >
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-autocomplete
                                        no-filter
                                        v-model="pedido.idcliente"
                                        outlined
                                        dense
                                        :search-input.sync="search"
                                        :items="items"
                                        :loading="carregando"
                                        item-text="nome"
                                        item-value="idcliente"
                                        class="mb-n10"
                                        @change="listarEnderecoCliente(), listarFormPagCliente()"
                                    >
                                        <template slot="item" slot-scope="{ item }">
                                            <v-row no-gutters>
                                                <v-col cols="12" class="text-subtitle-2">{{item.idcliente}} - {{item.nome}}</v-col>
                                                <v-col
                                                    v-if="item.cpfcnpj.length == 11"
                                                    cols="12"
                                                    class="text-subtitle-2"
                                                >CPF: {{item.cpfcnpj | formataCpf}}</v-col>
                                                <v-col v-else cols="12" class="text-subtitle-2">CNPJ: {{item.cpfcnpj | formataCnpj}}</v-col>
                                            </v-row>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col v-if="pedido.idcliente == 35" cols="12">
                                    <div class="text-body-1 font-weight-bold">Nome do cliente</div>
                                    <v-text-field class="mb-n10" outlined dense name="name"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-body-1 font-weight-bold">Endereço de entrega</div>
                                    <v-select
                                        v-model="pedido.idlocalentrega"
                                        class="mb-n10"
                                        :items="enderecos"
                                        item-value="idlocalentrega"
                                        item-text="localentrega"
                                        outlined
                                        dense
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn small text color="primary" @click="step = 1">Voltar</v-btn>
                                    <v-btn :disabled="verificaStep2()" small color="primary" @click="step = 3">Próximo</v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                        <v-stepper-step
                            :complete="step > 3"
                            step="3"
                            class="px-0 py-1 font-weight-bold"
                        >Dados de pagamento</v-stepper-step>
                        <v-stepper-content step="3" class="px-2 mx-0 font-weight-bold">
                            <v-row align="end" class="d-block">
                                <div v-for="(p,i) in pedido.pagamento" :key="i">
                                    <div class="ma-3 pb-3 rounded" style="border: 1px solid #BBB">
                                        <v-col cols="12">
                                            <div class="text-body-1 font-weight-bold">
                                                Forma de pagamento
                                                <v-btn
                                                    fab
                                                    x-small
                                                    text
                                                    color="error"
                                                    class="float-right mt-n2"
                                                    v-if="i != 0"
                                                    @click="excluiFrmPgto(i)"
                                                >
                                                    <v-icon class="font-weight-bold">mdi-close</v-icon>
                                                </v-btn>
                                            </div>
                                            <v-select
                                                v-model="p.idrecebimento"
                                                class="mb-n10"
                                                :items="formaPags"
                                                item-text="recebimento"
                                                item-value="idrecebimento"
                                                outlined
                                                dense
                                                @change="listarCondPagCliente(i), p.valor = 0, p.entrada = 0"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12">
                                            <div class="text-body-1 font-weight-bold">Valor</div>
                                            <v-text-field
                                                v-model.number="p.valor"
                                                type="number"
                                                class="mb-n10"
                                                outlined
                                                dense
                                                @change="p.valor = atualizarFrmPgto(p.valor, i), p.entrada = (p.valor * 0.2).toFixed(2)"
                                                @dblclick="p.valor = (calculaValorTotal() - calculaFrmPgtoTotal(i)).toFixed(2), p.entrada = (p.valor * 0.2).toFixed(2)"
                                            ></v-text-field>
                                        </v-col>
                                        <template v-if="condPags.length > 0">
                                            <template v-if="condPags[i]">
                                                <v-col cols="12" v-if="condPags[i].length > 0">
                                                    <div class="text-body-1 font-weight-bold">Condição de pagamento</div>
                                                    <v-select
                                                        v-model="p.idcondicao"
                                                        class="mb-n10"
                                                        :items="condPags[i].map(v => {return {...v, parcela: calculaParcela(v, p.valor, p.entrada).descricao}})"
                                                        item-text="parcela"
                                                        item-value="idcondicao"
                                                        outlined
                                                        dense
                                                        @change="calculaEntrada(i)"
                                                    ></v-select>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    v-if="condPags[i].length > 0 && condPags[i].filter(v => v.flagentrada == 'T' && v.idcondicao == p.idcondicao).length > 0"
                                                >
                                                    <div class="text-body-1 font-weight-bold">Entrada</div>
                                                    <v-text-field v-model.number="p.entrada" type="number" class="mb-n10" outlined dense></v-text-field>
                                                </v-col>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                                <v-col cols="12">
                                    <span> Saldo restante: {{(calculaValorTotal() - calculaFrmPgto()).toFixed(2) | formataDinheiro}}</span>
                                    <v-btn
                                        v-if="(calculaValorTotal() - this.calculaFrmPgto()).toFixed(2) != 0"
                                        :disabled="validarFrmPgto()"
                                        fab
                                        x-small
                                        class="mr-2 mt-n1 float-end"
                                        color="primary"
                                        elevation="0"
                                        @click="adcFrmPgto()"
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn small text color="primary" @click="step = 2">Voltar</v-btn>
                                    <v-btn :disabled="verificaStep3()" small color="primary" @click="step = 4">Próximo</v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                        <v-stepper-step
                            :complete="step > 4"
                            step="4"
                            class="px-0 py-1 font-weight-bold"
                        >Dados complementares</v-stepper-step>
                        <v-stepper-content step="4" class="px-2 mx-0 font-weight-bold">
                            <v-row align="end">
                                <v-col cols="12">
                                    <div class="text-body-1 font-weight-bold">Observação</div>
                                    <v-textarea class="mb-n10" outlined rows="1" v-model="pedido.observacao"></v-textarea>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn small text color="primary" @click="step = 3">Voltar</v-btn>
                                    <v-btn small color="primary" @click="step = 5">Próximo</v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                        <v-stepper-step
                            :complete="step > 5"
                            step="5"
                            class="px-0 py-1 font-weight-bold"
                        >Confirmação do pedido</v-stepper-step>
                        <v-stepper-content step="5" class="px-0 mx-0 font-weight-bold">
                            <v-row align="end" v-if="step == 5">
                                <v-col cols="12" class="mb-n5">
                                    <div class="py-0 text-caption font-weight-bold text-truncate">Cliente</div>
                                    <div
                                        v-if="items.length > 0"
                                        class="py-0 text-caption text-truncate"
                                    >{{pedido.idcliente}} - {{items.filter(v=>v.idcliente == pedido.idcliente)[0].nome}}</div>
                                </v-col>
                                <v-col cols="12" class="mb-n5">
                                    <div class="py-0 text-caption font-weight-bold text-truncate">Endereço</div>
                                    <div
                                        v-if="enderecos.length > 0"
                                        class="py-0 text-caption text-truncate"
                                    >{{enderecos.filter(v=>v.idlocalentrega == pedido.idlocalentrega)[0].localentrega}}</div>
                                </v-col>
                                <v-col cols="12" class="mb-n5">
                                    <div class="py-0 text-caption font-weight-bold text-truncate">Forma de pagamento</div>
                                    <div class="py-1 text-caption text-truncate">
                                        <v-simple-table dense>
                                            <thead>
                                            <tr>
                                                <th colspan="3" class="white--text" bgcolor="#023449" style="border: #023449 solid 2px; border-right: 0px; border-top-left-radius : 3px">Recebimento</th>
                                                <th colspan="1" class="white--text" bgcolor="#023449" style="border: #023449 solid 2px; border-left: 0px; border-top-right-radius : 3px">Valor</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <template v-for="(pg, i) in pedido.pagamento">
                                                <tr :key="i">
                                                    <td colspan="3" class="text-caption" :style="pg.idcondicao && condPags[i].length > 0 ? 'border-left: #023449 1px solid; border-bottom: 0px' : 'border-bottom: #023449 1px solid; border-left: #023449 1px solid'">
                                                        <!--															<v-icon color="#023449" class="mb-1">mdi-cash</v-icon> -->
                                                        <span v-if="formaPags.length > 0">{{formaPags.filter(v => v.idrecebimento == pg.idrecebimento)[0].recebimento}}</span>
                                                    </td>
                                                    <td colspan="1" class="text-caption" :style="pg.idcondicao && condPags[i].length > 0 ? 'border-right: #023449 1px solid; border-bottom: 0px' : 'border-bottom: #023449 1px solid; border-right: #023449 1px solid'">{{pg.valor | formataDinheiro}}</td>
                                                </tr>
                                                <tr :key="`${i}_${pg.idcondicao}`" v-if="pg.idcondicao && condPags[i].length > 0">
                                                    <td colspan="3" class="text-caption" style="border-bottom: #023449 1px solid; border-left: #023449 1px solid;">
                                                        <v-icon color="#023449" small class="mb-2 ml-1">mdi-arrow-right-bottom</v-icon>
                                                        <span v-if="condPags[i].length > 0" class="ml-1"
                                                        >{{calculaParcela(condPags[i].filter(v => v.idcondicao == pg.idcondicao)[0], pg.valor, pg.entrada).descricao}},</span>
                                                        <!--                                                            <span class="font-weight-bold">Total:</span>-->
                                                        <!--                                                            <span-->
                                                        <!--                                                                v-if="condPags[i].length > 0" class="font-weight-bold"-->
                                                        <!--                                                            > {{calculaParcela(condPags[i].filter(v => v.idcondicao == pg.idcondicao)[0], pg.valor, pg.entrada).total.toFixed(2) | formataDinheiro}}</span>-->
                                                    </td>
                                                    <td colspan="1" class="text-caption" style="border-bottom: #022E40 1px solid; border-right: #022E40 1px solid">
                                                        <span class="font-weight-bold pl-1 ml-n10">Total:</span>
                                                        <span
                                                            v-if="condPags[i].length > 0"
                                                        > {{calculaParcela(condPags[i].filter(v => v.idcondicao == pg.idcondicao)[0], pg.valor, pg.entrada).total.toFixed(2) | formataDinheiro}}</span>
                                                    </td>
                                                </tr>
                                            </template>
                                            </tbody>
                                        </v-simple-table>
                                    </div>
                                </v-col>
                                <v-col cols="12" class="mb-n5">
                                    <div
                                        class="py-0 text-caption font-weight-bold text-truncate"
                                    >{{produtos.length}} Produto(s)</div>
                                    <div class="py-0 text-caption text-truncate">{{calculaValor() | formataDinheiro}}</div>
                                </v-col>
                                <v-col cols="12" class="mb-n5">
                                    <div class="py-0 text-caption font-weight-bold text-truncate">Desconto</div>
                                    <div
                                        class="py-0 text-caption text-truncate"
                                    >{{(calculaValor()-calculaValorTotal()) | formataDinheiro}}</div>
                                </v-col>
                                <v-col cols="12" class="mb-n5">
                                    <div class="py-0 text-caption font-weight-bold text-truncate">Juros</div>
                                    <div
                                        class="py-0 text-caption text-truncate"
                                    >{{(calculaFrmPgtoComJuros()-calculaValorTotal()) | formataDinheiro}}</div>
                                </v-col>
                                <v-col cols="12">
                                    <div class="py-0 text-caption font-weight-bold text-truncate">Total</div>
                                    <div class="py-0 text-caption text-truncate">{{calculaFrmPgtoComJuros() | formataDinheiro}}</div>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn small text block color="primary" class="mb-1" @click="step = 4">Voltar</v-btn>
                                    <v-btn
                                        :disabled="produtos.length == 0"
                                        color="primary"
                                        block
                                        small
                                        class="mb-1"
                                        elevation="0"
                                        @click="dialogConfirmacaoPedido = true"
                                    >Gerar venda</v-btn>
                                    <v-btn
                                        :disabled="produtos.length == 0"
                                        color="#ccc"
                                        block
                                        small
                                        elevation="0"
                                        @click="dialogConfirmacaoOrcamento = true"
                                    >Gerar orçamento</v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                    </v-stepper>
                </v-card-text>
                <v-card-actions class="px-4 mt-n10">
                    <v-row>
                        <v-col cols="12">
                            <v-btn
                                class="text-decoration-underline"
                                block
                                elevation="0"
                                color="primary"
                                to="/venda"
                                text
                                small
                                exact
                            >Adicionar mais produtos</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import ClienteNovo from "../Cliente/ClienteNovo.vue";
import CardDialog from "../Widgets/CardDialog.vue";
import VendaProdutoDialog from "./VendaProdutoDialog";

export default {
    components: { ClienteNovo, CardDialog, VendaProdutoDialog},
    name: "VendaPedidoFaturar",
    data: () => ({
        dialogProduto: false,
        dialogConfirmacaoOrcamento: false,
        dialogConfirmacaoPedido: false,
        dialogCliente: false,
        dialogErro: {
            status: false,
            title: "",
            msg: "",
            icon: "",
            iconColor: "",
        },
        step: 1,
        carregando: false,
        produtos: [],
        descontoGeral: null,
        pontoRetiradaGeral: null,
        vendedorGeral: null,
        pedido: {
            idlocalentrega: 0,
            pagamento: [{ idrecebimento: 17, valor: 0, entrada: 0, idcondicao: 0, prazovista: 'V' }],
        },
        clientes: [],
        vendedores: [],
        locaisretirada: [],
        enderecos: [],
        formaPags: [],
        condPags: [],
        aguardarBusca: "",
        nomeLimit: 50,
        search: null,

        iconepromocao: '',
        produto: {},
        flagpromocaotodos: true,
        buscaProduto: '',
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "timeLimit", "usuario"]),
        items() {
            if (this.clientes == null) return;
            this.clientes.forEach((cliente) => {
                if (cliente.nome.length > this.nomeLimit) {
                    cliente.nome =
                        cliente.nome.slice(0, this.nomeLimit) + "...";
                }
            });
            return this.clientes;
        },
    },
    methods: {
        // Ativa e desativa promoção de todos os produtos atualizando valores
        alteraStatusPromocaoGeral(){
            if (this.flagpromocaotodos){
              this.produtos.forEach((u) => {
                  if (u.promocao != 0) {
                      u.flagpromocao = true;
                      setTimeout(() => {
                          u.desconto = 0;
                      }, 500);
                  }
              });
            } else {
              this.produtos.forEach((u) => ( u.flagpromocao = false ));
            }
            this.atualizaArrayProdutos();
        },
        // Carrega especificacoes do item selecionado
        get(item) {
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}venda/produto/especificacoes`, {
                    idsubproduto: item.idsubproduto,
                })
                .then((res) => {
                    this.produto = res.data;
                    this.carregando = false;
                })
                .catch(() => {
                    this.carregando = false;
                });
        },
        // Início das funções de listagem
        listarLocalRetirada() {
            return axios
                .post(`${this.backendUrl}venda/produto/localretirada`)
                .then((res) => {
                    this.locaisretirada = res.data;
                    this.locaisretirada = this.locaisretirada.map((v) => {
                        return {
                            idlocalretirada: v.idlocalretirada,
                            localretirada: `${v.idlocalretirada} - ${v.localretirada}`,
                        };
                    });
                });
        },
        listarEnderecoCliente() {
            return axios
                .post(`${this.backendUrl}venda/cliente/endereco/listar`, {
                    idcliente: this.pedido.idcliente,
                })
                .then((res) => {
                    this.enderecos = res.data;
                    this.enderecos = this.enderecos.map((v) => {
                        return {
                            ...v,
                            localentrega: `${v.enderecoentrega}, Nº ${v.numero} - ${v.bairroentrega} (${v.cidade}-${v.uf})`,
                        };
                    });
                });
        },
        listarFormPagCliente() {
            return axios
                .post(`${this.backendUrl}venda/cliente/formapagto/listar`, {
                    idcliente: this.pedido.idcliente,
                })
                .then((res) => {
                    this.formaPags = res.data;
                    this.formaPags = this.formaPags.map((v) => {
                        return {
                            ...v,
                            recebimento: `${v.idrecebimento} - ${v.recebimento}`,
                        };
                    });
                    this.pedido.pagamento[0].idrecebimento = 17;
                    this.pedido.pagamento[0].prazovista = 'V';
                    this.pedido.pagamento[0].valor = this.calculaValorTotal();
                });
        },
        listarCondPagCliente(posicao) {
            this.condPags[posicao] = [];
            this.pedido.pagamento[posicao].idcondicao = 0;
            this.pedido.pagamento[posicao].prazovista = 'V';
            return axios
                .post(`${this.backendUrl}venda/cliente/condpagto/listar`, {
                    idrecebimento: this.pedido.pagamento[posicao].idrecebimento,
                })
                .then((res) => {
                    if (res.data) {
                        // console.log(res.data)
                        // this.pedido.pagamento[0].entrada = (
                        //     this.calculaValorTotal() * 0.20
                        // ).toFixed(2);
                        this.condPags[posicao] = res.data;
                        this.pedido.pagamento[posicao].prazovista = this.formaPags.filter(u => u.idrecebimento == this.pedido.pagamento[posicao].idrecebimento)[0].prazovista
                    }
                    this.pedido.pagamento[posicao].valor = (this.calculaValorTotal() - this.calculaFrmPgtoTotal(posicao)).toFixed(2);
                });
        },
        listarClientes(val) {
            clearTimeout(this.aguardarBusca);
            this.aguardarBusca = setTimeout(() => {
                return axios
                    .post(`${this.backendUrl}cliente/listar`, {
                        limit: 30,
                        offset: 0,
                        busca: val || null,
                    })
                    .then((res) => {
                        this.clientes = res.data.lista;
                    });
            }, this.timeLimit);
        },
        listarVendedores() {
            return axios
                .post(`${this.backendUrl}cliente/listar`, {
                    limit: 999999,
                    offset: 0,
                    tipo: "'V'",
                    busca: null,
                })
                .then((res) => {
                    this.vendedores = res.data.lista;
                    this.vendedores.forEach((vendedor) => {
                        vendedor.nome = `${vendedor.idcliente} - ${vendedor.nome}`;
                        if (vendedor.nome.length > this.nomeLimit) {
                            vendedor.nome =
                                vendedor.nome.slice(0, this.nomeLimit) + "...";
                        }
                    });
                });
        },
        // Final das funções de listagem
        // Atualiza array de produtos no LocalStorage
        atualizaArrayProdutos() {
            localStorage.setItem("pedido", JSON.stringify(this.produtos));
            if (this.produtos.length == 0) {
                this.$router.push("/venda");
            }
            this.produtos.sort((a, b) => a.idsubproduto - b.idsubproduto);
            this.modificaSwitchGeral();
        },
        // Verifica status do switch de promoção geral
        modificaSwitchGeral(){
            let flags1 = this.produtos.filter(u => u.flagpromocao == true).length
            let flags2 = this.produtos.filter(u => u.promocao != 0).length
            if (flags1==flags2){
                this.flagpromocaotodos = true
            } else {
                this.flagpromocaotodos = false
            }
        },
        // Função chamada sempre um produto tem algum campo alterado
        atualizarProdutoPedido(produto, i) {
            this.produtos.splice(i, 1);
            if (produto.quantidade > 0) {
                if (produto.flagpromocao){
                    setTimeout(() => {
                        produto.desconto = 0;
                    }, 500);
                }
                this.produtos.push(produto);
            }
            this.atualizaArrayProdutos();
        },
        // Função chamada para limpar informações do pedido* item.quantidade
        limparPedido() {
            this.condPags = [];
            this.formaPags = [];
            this.enderecos = [];
            this.pedido = {
                idlocalentrega: 0,
                pagamento: [{ idrecebimento: 17, valor: 0, entrada: 0, idcondicao: 0 }],
            };
        },
        // Função chamada para excluir produto do array de produtos
        excluirProdutoPedido(i) {
            this.produtos.splice(i, 1);
            this.atualizaArrayProdutos();
        },
        // Função para atualizar o desconto em todos os produtos
        atualizarDescontoGeral() {
            // TODO: Validar se o produto está em promoção para não deixar inserir mais desconto
            this.produtos.forEach((v) => {
                if ( !v.flagpromocao ) {
                    v.desconto = this.descontoGeral
                } else {
                    v.desconto = 0
                }
            });
            this.atualizaArrayProdutos();
        },
        // Função para atualizar o ponto de retirada em todos os produtos
        atualizarPontoRetiradaGeral() {
            this.produtos.forEach(
                (v) => (v.idlocalretirada = this.pontoRetiradaGeral)
            );
            this.atualizaArrayProdutos();
        },
        // Função para atualizar o vendedor em todos os produtos
        atualizarVendedorGeral() {
            this.produtos.forEach((v) => (v.idvendedor = this.vendedorGeral));
            this.atualizaArrayProdutos();
        },
        // Função para adicionar uma nova forma de pagamento padrão em dinheiro (somente quando à saldo pendente)
        adcFrmPgto() {
            this.pedido.pagamento.push({
                prazovista: 'V',
                idrecebimento: 17,
                valor: (this.calculaValorTotal() - this.calculaFrmPgtoTotal(this.pedido.pagamento.length)).toFixed(2),
                entrada: 0,
                idcondicao: 0,
            });
        },
        // Função para excluir uma nova forma de pagamento
        excluiFrmPgto(i) {
            this.pedido.pagamento.splice(i, 1);
            this.condPags.splice(i, 1);
        },
        // Função para bloquear que o valor da forma de pagamento seja superior ao saldo pendente
        atualizarFrmPgto(valor, i) {
            if (
                valor >
                (
                    this.calculaValorTotal() - this.calculaFrmPgtoTotal(i)
                ).toFixed(2) ||
                valor <= 0
            ) {
                return (
                    this.calculaValorTotal() - this.calculaFrmPgtoTotal(i)
                ).toFixed(2);
            } else {
                return parseFloat(parseFloat(valor).toFixed(2));
            }
        },
        // Função para validar que o valor da forma de pagamento seja diferente de 0 ou null
        validarFrmPgto() {
            if (
                this.pedido.pagamento.filter(
                    (v) => v.valor == 0 || v.valor == null
                ).length > 0
            ) {
                return true;
            }
            return false;
        },
        // Função que calcula valor do pedido sem desconto
        calculaValor() {
            let valor = 0;
            this.produtos.forEach((v) => {
                if ( v.flagpromocao ) {
                    valor += v.promocao * v.valmultivendas * v.quantidade;
                } else {
                    valor += v.venda * v.valmultivendas * v.quantidade;
                }
            });
            return parseFloat(parseFloat(valor).toFixed(2));
        },
        // Função que calcula valor do pedido com desconto
        calculaValorTotal() {
            let valor = 0;
            let desconto = 0;
            this.produtos.forEach((v) => {
                if( v.flagpromocao && v.promocao !=0 ){
                    valor += ( v.promocao * v.valmultivendas ) * v.quantidade;
                } else {
                    if (v.desconto) {
                        desconto = v.venda * v.valmultivendas * (v.desconto / 100);
                    }
                    valor += (v.venda * v.valmultivendas - desconto) * v.quantidade;
                    desconto = 0;
                }

                // return parseFloat(parseFloat(valor).toFixed(2));
                // if (v.desconto) {
                //     desconto = v.venda * v.valmultivendas * (v.desconto / 100);
                // }
                // if ( v.flagpromocao ) {
                //     valor += v.promocao * v.valmultivendas * v.quantidade;
                // } else {
                //     valor += (v.venda * v.valmultivendas - desconto) * v.quantidade;
                // }

                // console.log('-- Produto',v.descricao)
                // console.log('-- preço da venda',v.venda,' -- preço da promoção',v.promocao,' -- valor ',valor)
            });
            // console.log('----------------------------------------funcao',valor)
            return parseFloat(parseFloat(valor).toFixed(2));
        },
        // Função que calcula valor total informado nas formas de pagamento
        calculaFrmPgto() {
            let valor = 0;
            if (this.pedido.pagamento) {
                this.pedido.pagamento.forEach((v) => {
                    valor += parseFloat(v.valor) || 0;
                });
            }
            return parseFloat(parseFloat(valor).toFixed(2));
        },
        // Função que calcula valor informado nas formas de pagamento, menos a forma de pagamento da prosição informada
        calculaFrmPgtoTotal(i) {
            let valor = 0;
            let arr = JSON.parse(JSON.stringify(this.pedido.pagamento));
            arr.splice(i, 1);
            if (arr) {
                arr.forEach((v) => {
                    valor += parseFloat(v.valor) || 0;
                });
            }
            return parseFloat(parseFloat(valor).toFixed(2));
        },
        // Função que calcula valor informado nas formas de pagamento, considerando os valores a prazo.
        calculaFrmPgtoComJuros() {
            let valor = 0;
            if (this.pedido.pagamento) {
                this.pedido.pagamento.forEach((v, i) => {
                    let flag = this.formaPags.filter(
                        (f) => f.idrecebimento == v.idrecebimento
                    )[0].prazovista;
                    if (flag == "V") {
                        valor += parseFloat(v.valor) || 0;
                    } else {
                        if (this.condPags[i]) {
                            if (this.condPags[i].length > 0) {
                                valor +=
                                    parseFloat(
                                        this.calculaParcela(
                                            this.condPags[i].filter(
                                                (c) =>
                                                    c.idcondicao == v.idcondicao
                                            )[0],
                                            v.valor,
                                            v.entrada
                                        ).total
                                    ) || 0;
                            }
                        }
                    }
                });
            }
            return parseFloat(parseFloat(valor).toFixed(2));
        },
        // Função que calcula as condições de pagamentos para o filtro de parcelas
        calculaParcela(p, valor, vlrEntrada) {
            valor = parseFloat(valor) || 0;
            vlrEntrada = parseFloat(vlrEntrada) || 0;
            let entrada = "s/ entrada";
            if (p.flagentrada == "T") {
                entrada = "c/ entrada";
            }
            // Com juros e sem entrada
            if (p.peracrvenda > 0 && p.flagentrada == "F") {
                let a =
                    (1 + parseFloat(p.peracrvenda) / 100) **
                    parseInt(p.qtdPagamento) *
                    (parseFloat(p.peracrvenda) / 100);
                let b =
                    (1 + parseFloat(p.peracrvenda) / 100) **
                    parseInt(p.qtdPagamento) -
                    1;
                let parcela = Math.ceil(valor * (a / b) * 100) / 100;
                let total = parcela * p.qtdPagamento;
                return {
                    descricao: `${p.qtdPagamento} x R$ ${parcela.toFixed(
                        2
                    )} ${entrada}`,
                    parcela: parcela,
                    total: total,
                };
            }
            // Com juros e com entrada
            if (p.peracrvenda > 0 && p.flagentrada == "T") {
                let a =
                    (1 + parseFloat(p.peracrvenda) / 100) **
                    (parseInt(p.qtdPagamento) - 1) *
                    (parseFloat(p.peracrvenda) / 100);
                let b =
                    (1 + parseFloat(p.peracrvenda) / 100) **
                    (parseInt(p.qtdPagamento) - 1) -
                    1;
                let parcela =
                    Math.ceil((valor - vlrEntrada) * (a / b) * 100) / 100;
                let total = vlrEntrada + parcela * (p.qtdPagamento - 1);
                return {
                    descricao: `1 x R$ ${vlrEntrada.toFixed(2)} + ${
                        parseInt(p.qtdPagamento) - 1
                    } x R$ ${parcela.toFixed(2)} ${entrada}`,
                    parcela: parcela,
                    total: total,
                };
            }
            // Sem juros e sem entrada
            if (p.peracrvenda <= 0 && p.flagentrada == "F") {
                let parcela = valor / p.qtdPagamento;
                let total = valor;
                return {
                    descricao: `${p.qtdPagamento} x R$ ${parcela.toFixed(
                        2
                    )} ${entrada}`,
                    parcela: parcela,
                    total: total,
                };
            }
            // Sem juros e com entrada
            if (p.peracrvenda <= 0 && p.flagentrada == "T") {
                let parcela = (valor - vlrEntrada) / (p.qtdPagamento + 1);
                let total = valor;
                return {
                    descricao: `1 x R$ ${vlrEntrada.toFixed(2)} + ${
                        p.qtdPagamento
                    } x R$ ${parcela.toFixed(2)} ${entrada}`,
                    parcela: parcela,
                    total: total,
                };
            }
        },
        calculaEntrada(posicao){
            if (this.condPags[posicao].filter(u => (u.idcondicao == this.pedido.pagamento[posicao].idcondicao))[0].flagentrada == 'T') {
                this.pedido.pagamento[posicao].entrada = (((this.calculaValorTotal() - this.calculaFrmPgtoTotal(posicao)).toFixed(2) * 0.2).toFixed(2));
            }
        },
        // Função que calcula o valor do produto na tabela sem desconto
        calculaValorProdutoTabela(produto) {
            // let valor = 0;
            // if (produto.promocao > 0) {
            //     valor = produto.promocao * produto.valmultivendas;
            // } else {
            //     valor = produto.venda * produto.valmultivendas;
            // }
            // return parseFloat(parseFloat(valor).toFixed(2));
            let valor = 0;
            // valor = produto.venda * produto.valmultivendas;
            valor = produto.venda
            return parseFloat(parseFloat(valor).toFixed(2));
        },
        // Função que calcula o subtotal do produto na tabela com desconto x quantidade
        calculaSubTotalProdutoTabela(produto) {
            let valor = 0;
            let desconto = 0;
            if( produto.flagpromocao && produto.promocao !=0 ){
                valor = ( produto.promocao * produto.valmultivendas )
                valor = valor * produto.quantidade;
            } else {
                if (produto.desconto) {
                    desconto = produto.venda * produto.valmultivendas * (produto.desconto / 100);
                }
                valor = produto.venda * produto.valmultivendas - desconto;
                valor = valor * produto.quantidade;
            }
            return parseFloat(parseFloat(valor).toFixed(2));
        },
        verificaStep1() {
            if (
                this.produtos.filter((v) => !v.idvendedor).length > 0 ||
                this.produtos.filter((v) => !v.idlocalretirada).length > 0 ||
                this.produtos.length == 0
            ) {
                return true;
            }
            return false;
        },
        verificaStep2() {
            if (
                (!this.pedido.idlocalentrega &&
                    this.pedido.idlocalentrega != 0) ||
                !this.pedido.idcliente
            ) {
                return true;
            }
            return false;
        },
        verificaStep3() {
            // TODO: Validar se o a forma de pagamento tem condições de pagamento e se a mesma foi preenchida
            if (( this.pedido.pagamento.filter(u => u.prazovista == 'P' && u.idcondicao == 0).map(v => {return {idcondicao: v.idcondicao} }).length > 0 )
                || ((this.calculaValorTotal() - this.calculaFrmPgto()).toFixed(2) != 0 )){
                return true;
            }
            return false;
        },
        // Função que busca produtos de um orçamento anterior
        exportarProdutos() {
            this.carregando = true;
            this.produtos = [];
            localStorage.removeItem("pedido");
            return axios
                .post(`${this.backendUrl}venda/pedido/exportar`, {
                    idorcamento: parseInt(this.$route.params.idorcamento),
                })
                .then((res) => {
                    let dados = res.data;
                    dados.forEach((v) => {
                        v.desconto = 0;
                        v.idvendedor = this.usuario.idcliente;
                    });
                    this.produtos = dados;
                    this.atualizaArrayProdutos();
                    this.carregando = false;
                });
        },
        // Função que gera um novo pedido
        gerarPedido() {
            this.carregando = true;
            this.produtos.forEach((v) => {
                v.desconto = parseFloat(v.desconto);
                v.flagpromocao = v.flagpromocao ? 'T' : 'F'
            });
            this.pedido.pagamento.forEach((v) => {
                v.entrada = parseFloat(v.entrada);
                v.valor = parseFloat(v.valor);
            });
            return axios
                .post(`${this.backendUrl}venda/gerar/pedido`, {
                    cabecalho: {
                        idcliente: this.pedido.idcliente,
                        cliente:
                            this.pedido.nome ||
                            this.items.filter(
                                (v) => v.idcliente == this.pedido.idcliente
                            )[0].nome,
                        observacao: this.pedido.observacao,
                        idlocalentrega: this.pedido.idlocalentrega,
                        pagamento: this.pedido.pagamento,
                        valor: this.calculaValorTotal(),
                    },
                    produtos: this.produtos,
                })
                .then((res) => {
                    this.carregando = false;
                    if (res.data != 0) {
                        localStorage.removeItem("pedido");
                        this.$router.push(`/venda/pedido/get/${res.data}`);
                    } else {
                        this.dialogErro.title = "Erro";
                        if (res.data.erro) {
                            this.dialogErro.msg =
                                "Não foi possível faturar o pedido!";
                        } else {
                            this.dialogErro.msg = res.data.erro;
                        }
                        this.dialogErro.icon = "mdi-alert-circle-outline";
                        this.dialogErro.iconColor = "red";
                        this.dialogErro.status = true;
                    }
                })
                .catch(() => {
                    this.carregando = false;
                    this.dialogErro.title = "Erro";
                    this.dialogErro.msg = "Não foi possível faturar o pedido!";
                    this.dialogErro.icon = "mdi-alert-circle-outline";
                    this.dialogErro.iconColor = "red";
                    this.dialogErro.status = true;
                });
        },
        // Função que gera um novo orçamento
        gerarOrcamento() {
            this.carregando = true;
            this.produtos.forEach((v) => {
                v.desconto = parseFloat(v.desconto);
                v.flagpromocao = v.flagpromocao ? 'T' : 'F'
            });
            return axios
                .post(`${this.backendUrl}venda/gerar/orcamento`, {
                    cabecalho: {
                        idcliente: this.pedido.idcliente,
                        cliente:
                            this.pedido.nome ||
                            this.items.filter(
                                (v) => v.idcliente == this.pedido.idcliente
                            )[0].nome,
                        observacao: this.pedido.observacao,
                        idlocalentrega: this.pedido.idlocalentrega,
                        pagamento: this.pedido.pagamento,
                        valor: this.calculaValorTotal(),
                    },
                    produtos: this.produtos,
                })
                .then((res) => {
                    this.carregando = false;
                    if (res.data != 0) {
                        localStorage.removeItem("pedido");
                        this.$router.push(`/venda/orcamento/get/${res.data}`);
                    } else {
                        this.dialogErro.title = "Erro";
                        if (res.data.erro) {
                            this.dialogErro.msg =
                                "Não foi possível faturar o pedido!";
                        } else {
                            this.dialogErro.msg = res.data.erro;
                        }
                        this.dialogErro.icon = "mdi-alert-circle-outline";
                        this.dialogErro.iconColor = "red";
                        this.dialogErro.status = true;
                    }
                })
                .catch(() => {
                    this.carregando = false;
                    this.dialogErro.title = "Erro";
                    this.dialogErro.msg = "Não foi possível gerar o orçamento!";
                    this.dialogErro.icon = "mdi-alert-circle-outline";
                    this.dialogErro.iconColor = "red";
                    this.dialogErro.status = true;
                });
        },
        async init() {
            if (this.$route.params.idorcamento) {
                this.exportarProdutos();
            } else {
                const json = localStorage.getItem("pedido");
                const produtos = JSON.parse(json);
                if (produtos) {
                    this.produtos = produtos;
                    this.produtos.sort(
                        (a, b) => a.idsubproduto - b.idsubproduto
                    );
                }
                if (this.produtos.length == 0) {
                    this.$router.push("/venda");
                }
            }
            await this.listarLocalRetirada();
            await this.listarVendedores();
            await this.atualizaArrayProdutos();
        },
    },
    watch: {
        search: function (val) {
            this.listarClientes(val);
        },
    },
    created() {
        this.init();
    },
};
</script>

<style scoped>
.custom-tooltip {
    opacity: 1!important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    padding: 10px!important;
}
.textoClicavel:hover{
    font-weight: bolder;
}
</style>